/* Global styles */
body {
  background-color: #e6eef5;
  font-family: 'Quicksand', sans-serif;
}

/* App container */
.App {
  text-align: center;
}

/* Header styles */
.navbar {
  background-color: #2c3e50;
}

.navbar-brand,
.navbar-nav .nav-link {
  color: #f0f5fa !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(240, 245, 250, 0.85)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

/* Jumbotron styles */
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background: linear-gradient(to bottom, #2c3e50, #3498db);
  border-radius: .3rem;
  color: #f0f5fa;
}

.carousel-item {
  background-color: rgba(240, 245, 250, 0.1);
  border-radius: 10px;
  padding: 20px;
}

/* Call to Action styles */
#cta {
  background: linear-gradient(to bottom, #d6e4f0, #e6eef5);
  padding: 30px;
  border-radius: 15px;
  color: #2c3e50;
}

/* Button styles */
.btn-primary {
  background-color: #3498db;
  border-color: #3498db;
  color: #f0f5fa;
}

.btn-primary:hover {
  background-color: #2980b9;
  border-color: #2980b9;
  color: #f0f5fa;
}

.btn-outline-secondary {
  color: #3498db;
  border-color: #3498db;
}

.btn-outline-secondary:hover {
  background-color: #3498db;
  color: #f0f5fa;
}

/* Gallery styles */
#gallery {
  background-color: #3498db;
  padding: 30px;
  border-radius: 15px;
}

#gallery .col-md-4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease-in-out;
}

.img-fluid:hover {
  transform: scale(1.05);
}